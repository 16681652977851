import {Component, Input, OnInit, Optional} from '@angular/core';
import {Translatable, TranslationService} from '@ngmedax/translation';
import {Questionnaire} from '@ngmedax/common-questionnaire-types';
import {ValueService} from '@ngmedax/value';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NgbTooltip} from '@ng-bootstrap/ng-bootstrap';
import {TRANSLATION_EDITOR_SCOPE} from '../../../constants';
import {KEYS} from '../../../translation-keys';
import {QuestionnaireVariablesService} from '../../services/questionnaire-variables.service';


// hack to inject decorator declarations. must occur before class declaration!
export interface FormulaVariablesComponent extends Translatable {}

@Component({
  selector: 'app-qa-formula-variables',
  templateUrl: './formula-variables.component.html',
  styleUrls: ['./formula-variables.component.css']
})
@Translatable({scope: TRANSLATION_EDITOR_SCOPE, keys: KEYS})
export class FormulaVariablesComponent implements OnInit {
  @Input() questionnaire: Questionnaire;
  public form: FormGroup;

  private varPath  = ['meta', 'options', 'variables'];
  private varNamePattern =  /^[a-zA-Z0-9_]{3,50}$/;
  private errors = {
    'pattern': this._(KEYS.EDITOR.INVALID_VAR_NAME_TEXT),
    'invalidExpression': this._(KEYS.EDITOR.INVALID_EXPRESSION),
    'invalidCircularExpression': this._(KEYS.EDITOR.INVALID_CIRCULAR_EXPRESSION)
  }

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    private questionnaireVariables: QuestionnaireVariablesService,
    private formBuilder: FormBuilder,
    private value: ValueService
  ) {
  }

  /**
   * Initializes formula variables and updates form
   */
  public ngOnInit() {
    this.initFormulaVariables();
    this.updateForm();
  }

  /**
   * Adds new formula variable
   */
  public onAdd() {
    const varPrefix = 'formula';
    const formulaVariables = this.questionnaireVariables.getVariablesForScope('formula');

    for (let i = 1; i < 1000; i++) {
      const varName = `${varPrefix}${i}`;
      if (!formulaVariables[varName]) {
        formulaVariables[varName] = {expression: ''};
        break;
      }
    }

    this.updateForm();
  }

  /**
   * Event handler for when a formula var is changed
   *
   * @param {string} varName
   * @param {string} previousVarName
   */
  public onEdit(varName: string, previousVarName: string) {
    const expressionValue = this.form.get(previousVarName + 'Expression')?.value;
    this.renameVariable('formula', varName, expressionValue, previousVarName);
    this.updateForm();
  }

  /**
   * Event handler for when formula var is reset to previous value
   *
   * @param {string} previousVarName
   */
  public onUndo(previousVarName: string) {
    const variables = this.questionnaireVariables.getVariablesForScope('formula');
    const previousExpression = this.value.get(variables, [previousVarName, 'expression']) || '';

    this.form.get(previousVarName).reset(previousVarName);
    this.form.get(previousVarName + 'Expression').reset(previousExpression);
  }

  /**
   * Event handler for when formula var is removed
   *
   * @param {string} varName
   */
  public onRemove(varName: string) {
    const formulaVariables = this.questionnaireVariables.getVariablesForScope('formula');
    formulaVariables[varName] && delete(formulaVariables[varName]);
    this.updateForm();
  }

  /**
   * Shows tooltip when current form element value is invalid
   *
   * @param {NgbTooltip} tooltip
   * @param {AbstractControl} formEl
   */
  public onCheckForTooltip(tooltip: NgbTooltip, formEl: AbstractControl) {
    if (formEl.dirty && formEl.errors) {
      formEl.errors.pattern && tooltip.open({tooltipMsg: this.errors.pattern});
      formEl.errors.invalidExpression && tooltip.open({tooltipMsg: this.errors.invalidExpression});
      formEl.errors.invalidCircularExpression && tooltip.open({tooltipMsg: this.errors.invalidCircularExpression});
      return;
    }

    tooltip && tooltip.isOpen() && tooltip.close();
  }

  /**
   * Returns array of formula variables by questionnaire or by form
   *
   * @param {boolean} byForm
   * @returns string[]
   */
  public getFormulaVariableNames(byForm = false): string[] {
    const formControls = this.value.get(this.form, ['controls']) || {};
    return Object.keys(byForm ? formControls : this.questionnaireVariables.getVariablesForScope('formula'));
  }

  /**
   * Returns variable data
   *
   * @param {string} varName
   * @returns {{type: string}}
   */
  public getVariableData(varName: string): {format: string, expression: string | number} {
    return <{format: string, expression: string}>
      this.questionnaireVariables.getVariablesForScope('formula')[varName] || {format: 'text', expression: ''};
  }

  /**
   * Renames variable
   */
  public renameVariable(scope: string, varName: string, expressionValue: string, previousVarName: string) {
    const variables = this.questionnaireVariables.getVariablesForScope(scope);

    if (!variables[previousVarName]) {
      return;
    }

    variables[varName] = this.value.clone(variables[previousVarName]);
    expressionValue = expressionValue.trim();
    !expressionValue.match(/^\(.*?\)$/) && (expressionValue = `(${expressionValue})`);
    expressionValue !== '' && (variables[varName].expression = expressionValue);
    previousVarName !== varName && delete(variables[previousVarName]);
  }

  /**
   * Updates formula variables form by questionnaire formula variables
   */
  private updateForm() {
    const varNames = this.getFormulaVariableNames();
    const dynFormGroup = {};

    for (const varName of varNames) {
      dynFormGroup[varName] = this.formBuilder.control(varName, [Validators.pattern(this.varNamePattern)]);

      const expressionValue = this.getVariableData(varName).expression || '';
      dynFormGroup[varName + 'Expression'] = this.formBuilder.control(expressionValue, (control: AbstractControl) => {
        const functionCalls = (control.value + '').match(/([a-zA-Z\.]+\()/g) || [];

        for (const functionCall of functionCalls) {
          if (!functionCall.match(/Math\.[a-zA-Z]+\(/)) {
            return {invalidExpression: true};
          }
        }

        if (control.value.match(/[\'\"]/)) {
          return {invalidExpression: true};
        }

        if (control.value.match(/formula\.[a-zA-Z]+/)) {
          return {invalidCircularExpression: true};
        }

        return null;
      });
    }

    this.form = this.formBuilder.group(dynFormGroup);
  }

  /**
   * Initializes questionnaire formula variables in questionnaire
   */
  private initFormulaVariables() {
    let current = this.questionnaire;
    [...this.varPath, 'formula'].forEach((path) => {
      !current[path] && (current[path] = {});
      current = current[path];
    });
  }
}
